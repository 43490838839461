
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.bs-checkbox{
	--el-checkbox-checked-text-color: #{$primary};
	--el-checkbox-checked-bg-color: #{$primary};
	--el-checkbox-checked-input-border-color: #{$primary};

	margin-right: 0;

	&:hover{
		.el-checkbox{
			// &__label{
			// 	color: $primary;
			// }
			&__inner{
				border-color: $primary;
			}
		}
	}
}