
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.bs-radio-group{  }

.bs-radio{
	margin-right: 24px;

	.el-radio{
		&__inner{
			&::after{
				@include size(6px);
			}
		}
		&__label{
			font-weight: 400;
		}
	}
}