
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.bs-multi-select{
	.el-select{
		&__wrapper{
			min-height: 40px;
		}

		&__selection.is-near{
			margin-left: -4px;
		}
	}
	.el-tag{
		// height: 32px;
		height: 24px;
	}

	&__opt{
		padding-left: 16px;

		&.is-selected{
			--el-color-primary: #{$primary};
		}
		&.is-hovering{
			--el-fill-color-light: #{$light-fill-color};
		}

		&.--show-checkbox{
			.el-checkbox__inner{
				transition: all 100ms;
			}

			&.is-hovering{
				.el-checkbox__inner{
					border-color: $primary;
				}
			}
			&.is-selected{
				&::after{
					content: none !important;
				}
			}
		}
	}
}