
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.cell-count-queue{
	&__edit{
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 8px;

		color: $btn-disabled;

		transition: 200ms;
		&:hover {
			color: $primary;
		}

		.ico{
			@include size(14px);
		}
	}
}