
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.loader-spinner{
	@include size(100%);
	@include flex-ctr;
	user-select: none;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 99;
	transform: translate(-50%, -50%);
	flex-direction: column;

	background-color: rgba($color: #fff, $alpha: 0.8);
	// backdrop-filter: blur(4px);

	&__spinner{
		@include size(60px);
		border-radius: 50%;
		position: relative;
		animation: rotate 1s linear infinite;

		&::before{
			content: "";
			box-sizing: border-box;
			position: absolute;
			inset: 0px;
			border-radius: 50%;
			border: 5px solid rgba($color: $light-text-secondary, $alpha: 0.7) ;
			animation: prixClipFix 2s linear infinite;
		}

		@keyframes rotate {
			100% {transform: rotate(360deg)}
		}

		@keyframes prixClipFix {
			0% {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
			25% {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
			50% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
			75% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
			100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
		}
	}
	&__title{
		margin-top: 24px;
	}
	&__num{
		position: absolute;
		top: calc(50% - 21px);
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 14px;
		color: $light-text-regular;
		font-weight: 500;
	}
}