
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.side-select{
	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	&__item{
		position: relative;

		padding: 5px 12px;
		margin: 0;
		border-radius: 4px;

		font-size: 14px;

		span{
			display: flex;
			align-items: center;
			gap: 4px;
		}

		.ico{
			width: 11px;
			height: 16px;
		}

		&.--is-light{
			padding: 5px 8px;
		}
	}

	&__digital-badge{
		@include size(16px);
		@include flex-ctr;
		// flex-direction: column;

		position: absolute;
		top: -4px;
		left: 100%;
		transform: translateX(-50%);

		// font-size: 4px;
		// line-height: 1;
		// color: $white;

		// border: 1px solid $white;
		// border-radius: 50%;
		// background-color: $primary;
		.ico{
			@include size(16px);
		}
	}

	.el-button+.el-button{
		margin: 0;
	}
}