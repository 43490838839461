
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.bs-input{
	--el-input-height: 40px;
	--el-input-text-color: #{$light-text-regular};
	--el-color-primary: #{ $primary };

	.el-input{
		&__inner{
			&::placeholder{
				font-size: $mainFontSize;
				font-family: $mainFont;
			}
		}
	}
	.el-textarea{
		&__inner{
			padding: 8px 12px;
		}
	}
}