
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.notif-center-table{
	@include size(100%);
	position: relative;

	.table-grid{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		--th-font-size: 12px;
		--td-font-size: 12px;
		--td-vertical-align: top;

		&__th{
			&.th-mass-actions{
				padding: 0;

				.table-grid__cell{
					@include flex-ctr;
				}
			}
		}
		&__td{
			&.td-mass-actions{
				padding: 0;

				.table-grid__cell{
					display: flex;
					justify-content: center;
				}
			}
		}
	}

	.cell-date{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
	}
}