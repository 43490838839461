.bs-message-box{
	--el-color-primary: #{$primary};
	font-family: $mainFont;

	.el-button{
		--el-button-border-color: #{$light-border};
		--el-button-hover-text-color: #{$light-text-regular};
		--el-button-hover-bg-color: #F2F2F2;
		--el-button-hover-border-color: #{$light-border};

		--el-button-active-text-color: #{$light-text-regular};
		--el-button-active-bg-color: #{$white};
		--el-button-active-border-color: #{$light-text-placeholder};

		--el-border-radius-base: 2px;

		font-family: $mainFont;

		&.el-button--primary{
			--el-button-bg-color: #{$primary};
			--el-button-border-color: #{$primary};
			--el-button-hover-text-color: #{$white};
			--el-button-hover-bg-color: #633EAA;
			--el-button-hover-border-color: #633EAA;

			--el-button-active-text-color: #{$white};
			--el-button-active-bg-color: #422181;
			--el-button-active-border-color: #422181;
			--el-button-outline-color: transparent;
		}

		&:disabled{
			--el-button-disabled-text-color: #{$btn-disabled};
			--el-button-disabled-bg-color: #{$white};
			--el-button-disabled-border-color: #{$btn-disabled};
		}
	}
}