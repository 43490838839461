
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.edit-queue-list{
	display: flex;
	flex-direction: column;

	&__head{
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: fit-content;

		padding: 8px 16px;
		border-bottom: 1px solid $light-border-3;

		&.pd-right{
			padding-right: 24px;
		}
	}
	&__head-check{
		.bs-checkbox{
			height: auto;
		}
	}
	&__head-remove{
		cursor: pointer;
		color: $light-text-secondary-2;

		transition: 200ms;
		&:hover{
			color: $primary;
			transform: scale(1.05);
		}
		&:active{
			transform: scale(0.98);
		}

		.ico{
			@include size(16px);
		}
	}
	&__body{
		@include scrollbar;
		overflow-y: auto;

		min-height: 80px;
		max-height: 380px;
		flex-grow: 1;

	}
	&__empty{
		@include flex-ctr;
		height: 80px;
		padding: 8px 16px;

		color: $light-text-secondary;
		font-weight: 500;
	}
	&__item{
		&:nth-of-type(2n+1){
			background-color: $light-fill-color-2;
		}
	}
	&__item-info{
		flex: 1;
	}
}