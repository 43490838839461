
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.nt-head-message{
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__mass{
		.cell-mass-actions__btn{
			width: 10px;
		}
	}
}