
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.table-grid{
	--th-font-size: 14px;
	--td-font-size: 14px;

	--th-text-align: center;
	--td-text-align: center;

	--th-vertical-align: middle;
	--td-vertical-align: middle;

	--th-padding: 2px 5px;
	--td-padding: 3px 5px;

	--th-cell-font-color: #{ $light-text-secondary };

	--th-bg-color: #{ $light-fill-color-2 };
	--ft-bg-color: #{ $light-fill-color-2 };


	@include scrollbar;
	@include size(100%);
	max-width: 100%;
	max-height: 100%;
	overflow: auto;

	border: 1px solid $light-border-3;

	&__table{
		width: 100%;
		border-collapse: collapse;

		// th:first-child,
		// td:first-child {
		// 	position: sticky;
		// 	left: 0;
		// 	// z-index: 0;
		// 	background-color: white;
		// }

		// th:first-child{
		// 	position: sticky;
		// 	left: 0;
		// 	// z-index: 10;
		// }
	}
	&__thead{
		position: sticky;
		top: 0;
		z-index: 100;
		background-color: var(--th-bg-color);
	}
	&__tfoot{
		position: sticky;
		bottom: 0;
		z-index: 100;
		background-color: var(--ft-bg-color);
	}

	&__th,
	&__td{
		border: 1px solid $light-border-3;
		border-top-width: 0px;

		&:nth-of-type(1){
			border-left-width: 0px;
		}
		&:nth-last-of-type(1){
			border-right-width: 0px;
		}
	}

	&__th{
		vertical-align: var(--th-vertical-align);
		text-align: var(--th-text-align);

		font-size: var(--th-font-size);
	}

	&__td{
		vertical-align: var(--td-vertical-align);
		text-align: var(--td-text-align);

		font-size: var(--td-font-size);
	}

	&__cell{
		@include size(100%);
	}


}