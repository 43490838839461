
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
@import '@/styles/common/el-dialog-mixin.scss';

.oper-error-popup{
	@include el-dialog;

	min-width: 500px;
	width: auto;

	&__content{
		color: $light-text-regular;
	}
}