$light-text-regular			: #303133;
$light-text-secondary		: #909399;
$light-text-secondary-2		: #606266;
$light-text-placeholder		: #A8ABB2;
$light-fill-color			: #F6F4FA;
$light-fill-color-2			: #F5F7FA;

$light-border				: #DCDFE6;
$light-border-2				: #E4E7ED;
$light-border-3				: #EBEEF5;
$darken-border				: #CDD0D6;
$body-background			: #F2F3F5;
$btn-disabled				: #C0C4CC;

$light-fill-blue			: #C6E2FF;
$light-fill-violet			: #ECD5FF;

$white						: #fff;
$black						: #000;
$primary					: #5229A1;
$primary-light				: #9747FF;
$primary-sky                : #ECF5FF80;
$primary-light-8			: #D9ECFF;
$primary-light-9			: #ECF5FF;
$danger						: #FF0000;
$error						: #F56C6C;
$warn						: #E4A13C;

$marker-free				: #67C23A;
$marker-busy				: #F56C6C;
$marker-added				: #00B2FF;
$marker-reserved			: #E6A23C;
$marker-reserved-2			: #E9D469;
$marker-reserved-light		: #fffbe9;
$marker-dismounted      	: #E9E9E9;

$reserved					: #FF9400;
$busy						: #FF5129;
$changing                   : #C8C9CC;
$part-sold					: #FF8569;

$base-radius				: 4px;
$shadow-light				: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
$shadow-light-2				: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);

// Font
$mainFont					: "Exo 2", sans-serif;
// Size
$mainFontColor				: $light-text-regular;
$mainFontSize				: 14px;
$mainFontWeight				: 400;
$mainLineHeight				: 22px;