
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.cell-operator{
	&__label{
		color: $primary;
		text-decoration: underline;

		transition: 200ms;
		&:hover{
			opacity: 0.7;
		}
	}
}