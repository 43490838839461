@mixin headline-size{
	font-weight: 500;
	line-height: 1.4;
}

@mixin h1{
	@include headline-size;
	font-size: 20px;
}
@mixin h2{
	@include headline-size;
	font-size: 18px;
	// line-height: 26px;
}
@mixin h3{
	@include headline-size;
	font-size: 16px;
	line-height: 1.5;
}

.h1{
	@include h1;
}
.h2{
	@include h2;
}
.h3{
	@include h3;
}