
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.main-menu{
	$this: &;

	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;

	border-radius: $base-radius;
	background-color: $white;
	box-shadow: $shadow-light-2;

	&__btn-collapse{
		@include flex-ctr;

		cursor: pointer;
		height: 58px;
		width: fit-content;
		padding: 0 20px;

		color: $black;

		transition: background-color 100ms;
		&:hover{
			background-color: rgba($color: $primary, $alpha: 0.05);
		}

		.ico{
			@include size(18px);
			display: block;

			fill: currentColor;
		}

		&.--is-collapse{
			.ico{
				transform: rotate(180deg);
			}
		}
	}

	&__menu{
		&:not(.el-menu--collapse) {
			width: 220px;
			max-width: 220px;
		}
		border-right: none;
	}
	&__item{
		--el-menu-item-height: 58px;
		--el-menu-active-color: #{$primary};
		--el-menu-hover-bg-color: #{rgba($color: $primary, $alpha: 0.05)};

		&.is-active{
			a{
				color: $primary;
			}
		}

		a{
			color: $light-text-regular;
		}

		gap: 8px;
	}
	&__item-title{
		font-weight: 500;

		color: $light-text-regular;
		// #{$this}__menu:not(.el-menu--collapse) &{
		// 	white-space: normal;
		// 	line-height: 1;
		// }

		.el-menu--collapse &{
			opacity: 0;
		}
	}
	&__item-icon{
		.ico{
			@include size(24px);
		}
	}
}