
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
@import '@/styles/common/el-dialog-mixin';

.edit-oper-queue-popup{
	@include el-dialog;
	width: auto;
	min-width: 500px;

	&__content{
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__select{
		width: 180px;

		.el-select{
			&__wrapper{
				min-height: 36px;
			}
		}
	}
}