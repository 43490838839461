
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.btn-action{
	@include size(36px);
	@include flex-ctr;
	position: relative;
	user-select: none;
	cursor: pointer;
	overflow: hidden;

	--border-color: transparent;
	--border-color--disabled: #{$btn-disabled};

	--color-icon: #{$primary};
	--color-icon--active: #{$white};
	--color-icon--disabled: #{$btn-disabled};

	--bg-color: #{$white};
	--bg-color--hover: #{$light-fill-color};
	--bg-color--active: #{$primary};
	--bg-color-active: #{$primary};
	--bg-color-active--hover: #633eaa;
	--bg-color-active--active: #422181;
	--bg-color--disabled: #{$white};

	border-radius: $base-radius;
	border: 1px solid var(--border-color);
	color: var(--color-icon);
	background-color: var(--bg-color);
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);

	transition: 100ms;

	.ico{
		@include size(16px, 17px);
		stroke: currentColor;
		fill: currentColor;
	}

	&:hover {
		background-color: var(--bg-color--hover);
	}

	&:active {
		background-color: var(--bg-color--active);
		color: var(--color-icon--active);
	}

	&--active{
		background-color: var(--bg-color-active);
		color: var(--color-icon--active);

		&:hover{
			background-color: var(--bg-color-active--hover);
		}
		&:active{
			background-color: var(--bg-color-active--active);
		}
	}

	&--disabled,
	&--disabled:hover,
	&--disabled:active{
		cursor: not-allowed;

		background-color: var(--bg-color--disabled);
		border-color: var(--border-color--disabled);
		color: var(--color-icon--disabled);
	}

	&__overlay-loading{
		@include flex-ctr;
		@include size(100%);
		position: absolute;
		top: 0;
		left: 0;

		background-color: rgba($color: $light-text-regular, $alpha: 0.2);
	}

	&__spinner{
		@include size(70%);
		border-radius: 50%;
		position: relative;
		animation: rotate 1s linear infinite;

		&::before{
			content: "";
			box-sizing: border-box;
			position: absolute;
			inset: 0px;
			border-radius: 50%;
			border: 3px solid rgba($color: $white, $alpha: 1) ;
			animation: prixClipFix 2s linear infinite;
		}

		@keyframes rotate {
			100% {transform: rotate(360deg)}
		}

		@keyframes prixClipFix {
			0% {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
			25% {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
			50% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
			75% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
			100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
		}
	}
}