
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
@import '@/styles/common/el-dialog-mixin.scss';

.oper-mass-employ-popup{
	@include el-dialog;

	&__content{
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__action-buttons{
		margin-top: 16px;
	}
}