
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.notif-user-info{
	--color-name: #{$marker-reserved};

	display: flex;
	align-items: center;
	gap: 8px;

	&__ava{
		display: flex;
		align-items: center;

		.bs-avatar{
			--el-avatar-text-size: 14px;
		}
	}
	&__name{
		color: var(--color-name);
	}
	&__contacts{
		display: flex;
		align-items: center;
		gap: 8px
	}
	&__contact-item{
		@include size(14px);
		cursor: pointer;
		color: $btn-disabled;

		transition: 100ms;

		&:hover{
			transform: scale(1.2);
			background-color: rgba($color: $primary, $alpha: 0.05);
			color: rgba($color: $primary, $alpha: 0.8);
		}

		.ico{
			@include size(14px);
		}
	}
}