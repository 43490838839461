
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
@import '../mixins/mixin-notif-message.scss';

.notif-message-system{
	@include notif-message;

	&__info{ }
}