@mixin notif-message {
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 8px;
	padding-bottom: 16px;

	&.--is-message-not-read{
		background-color: rgba($color: $primary, $alpha: 0.05);
	}

	&__head{
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
	}
	&__date{
		font-size: 11px;
		color: $light-text-secondary-2;
	}
	&__message{
		color: $light-text-regular;
	}
	&__btn-actions{
		display: flex;
		gap: 8px;

		.bs-btn{
			height: 24px;
			margin: 0;
			padding: 0 12px;
			border-radius: 2px;
		}
	}
}