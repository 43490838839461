
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.search-panel{
	$this: &;

	position: relative;
	display: flex;
	padding-left: 32px;

	&.--is-focus-panel,
	&:hover{
		.btn-action{
			border-color: $primary;
		}
	}

	&__btn-action{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		.btn-action{
			transition: 300ms;
			border-color: $light-border;
		}
	}
	&__input{
		position: relative;
		// width: 200px;
		flex: 1;
		z-index: 2;

		.bs-input{
			--el-input-height: 36px;
			// --el-input-border-color: #{$primary};
			--el-input-hover-border-color: #{$primary};
			--el-font-size-base: 12px;
		}

		.el-input{
			&__wrapper{
				padding: 1px 12px;
			}
			&__inner{
				&::placeholder{
					font-size: var(--el-font-size-base);
				}
			}
		}
	}
}