
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
@import '@/styles/common/views/mixins/default-view-mixin';

.operator-item-view{
	@include default-view;

	&__action-panel{
		position: relative;
		gap: 16px;
	}

	&__action-item{
		&.item-filter-aside{
			.el-badge{
				--el-badge-size: 16px;
				--el-badge-padding: 4px;
				// --el-badge-bg-color: #{$danger};
				--el-badge-font-size: 10px;


				&__content{
					min-width: 16px;
					// font-size: 10px;
					font-weight: 700;
					background-color: $danger;

					&.is-fixed{
						// right: -3px;
						// top: 7px;
					}
				}
			}
		}

		&.item-command-panel{
			position: absolute;
			top: 0;
			right: 0;
		}

		.bs-btn{
			height: 36px;
		}
	}

}