@mixin default-view{
	position: relative;
	height: 100%;
	padding: 16px;
	padding-left: 0;

	&__loader{
		@include size(100%);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		// background-color: rgba($color: $white, $alpha: 0.8);
	}

	&__container{
		@include size(100%);

		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__action-panel{
		position: relative;
		display: flex;
		align-items: center;
		// flex-wrap: wrap;
		gap: 24px;

		width: 100%;
		max-width: 100%;
	}

	&__action-panel-group{
		position: relative;
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__pagination{
		display: flex;
		justify-content: flex-end;
	}

	&__table{
		position: relative;
		flex-grow: 1;
		padding: 16px;

		border-radius: $base-radius;
		background-color: $white;
	}
}