
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.head-cell-default{
	$this: &;

	display: flex;
	justify-content: center;
	// gap: 8px;

	&:hover{
		#{$this}__btn-sort{
			padding-left: 8px;
			opacity: 1;
			max-width: 100%;
		}
	}

	&__label{
		flex: 1;
	}
	&__btn-sort{
		cursor: pointer;
		display: flex;
		align-items: center;

		overflow: hidden;
		max-width: 0;
		opacity: 0;
		// gap: 2px;
		transition: 500ms;

		&:hover{
			// color: $primary;
		}

		&.--sort-active{
			padding-left: 8px;
			opacity: 1;
			max-width: 100%;

			color: $primary;
		}
	}

	&__btn-sort-icon{
		.ico{
			@include size(14px);
		}
	}
}