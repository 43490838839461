
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							



.btn-head-notif{
	--el-button-text-color: #{$light-text-regular};
	--el-button-hover-text-color: #{$primary};
	--el-button-hover-bg-color: #{rgba($color: $primary, $alpha: 0.05)};

	border: none;

	.ico{
		@include size(16px);
		display: block;
		fill: currentColor;
	}
}