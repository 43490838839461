
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.bs-select-v2{
	&__popover{
		&.--show-checkbox{
			.el-select-dropdown__item{
				padding-left: 16px;

				&.is-selected:after{
					display: none;
				}
			}
		}
	}
}