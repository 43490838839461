
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							



.main-head-logo{
	position: relative;
	display: flex;
	align-items: center;
	gap: 7px;

	&__title{
		font-size: 14px;
		font-weight: 700;
		line-height: 1.5;
		text-transform: uppercase;

		color: $primary;
	}
	&__img{
		@include size(39px, 24px);
		display: block;
		object-fit: contain;
		object-position: center;
	}
}