
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.sign-in-view {
	@include size(100%);
	display: flex;
	justify-content: center;
	align-items: center;

	&__container {
		position: relative;
		padding: 100px;
		background: white;
	}

	&__logo{
		position: absolute;
		top: 36px;
		left: 50%;
		transform: translateX(-50%);

		width: fit-content;
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-input {
			width: 200px;
		}
	}
}