@mixin el-dialog{
	.el-dialog{
		&__header{
			padding: 16px;
			padding-right: 20px;

		}
		&__headerbtn{
			top: 3px;
		}
		&__title{
			font-weight: 500;
		}
		&__body{
			padding: 16px;
		}
		&__close{
			--el-color-primary: #{ $primary };
		}
	}

	&__action-buttons{
		display: flex;
		justify-content: flex-end;
		gap: 8px;

		margin-top: 32px;

		.bs-btn{
			--el-button-font-weight: 400;

			margin: 0;
			font-size: 14px;
			border-radius: 2px;
		}
	}
}