.control{
	$this: &;

	&__head{
		cursor: pointer;

		width: fit-content;
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 8px;

		transition: 200ms;
		&:hover{
			// transform: scale(1.2);
			background-color: rgba($color: $primary, $alpha: 0.05);
			color: rgba($color: $primary, $alpha: 0.8);
		}
	}
	&__head-label{
		font-size: 18px;
		color: $primary;
		line-height: 26px;
	}
	&__head-arrow{
		color: $light-text-placeholder;
		.ico{
			@include size(16px);
			transition: 200ms;
		}

		.--is-open-group &{
			.ico{
				transform: rotate(180deg);
			}
		}
	}

	&__label{
		display: flex;
		padding: 5px 0;

		#{$this}--required &{
			position: relative;
			width: fit-content;
			padding-right: 0.6em;

			&::before{
				content: '*';
				position: absolute;
				top: 0;
				right: 0;
				color: $danger;

				font-size: 1.1em;
				font-weight: 700;
			}
		}
	}
	&__label-info{
		transform: translate(20%, -10%);
	}
	&__group{
		&.group-row{
			display: flex;
			flex-wrap: wrap;
			gap: 16px;

			&.gap-8{
				gap: 8px;
			}
			&.gap-24{
				gap: 16px 24px;
			}
			&.gap-32{
				gap: 16px 32px;
			}
			&.gap-64{
				gap: 16px 64px;
			}
			&.row-space-between{
				justify-content: space-between;
			}
			&.flex-no-wrap{
				flex-wrap: nowrap;
			}
			&.align-center{
				align-items: center;
			}
		}
		&.group-col{
			display: flex;
			flex-direction: column;
			gap: 8px;

			&.gap-24{
				gap: 24px;
			}
		}
	}
	&__value{
		font-weight: 700;
		color: $primary;
	}
	&__dots{
		border-bottom: 1px dotted $light-border-2;
		flex: 1;

		margin: 0 8px;
	}
	&__builder{
		.bs-select-v2,
		.bs-single-select{
			min-width: 60px;
			width: 60px;
		}
	}

	&.full-free-width{
		flex: 1;
	}
}