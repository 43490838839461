
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.head-user-info{
	cursor: pointer;

	display: flex;
	align-items: center;
	gap: 8px;
	padding: 4px 8px;
	border-radius: 8px;

	transition: background-color 100ms;
	&:hover{
		background-color: rgba($color: $primary, $alpha: 0.05);
	}
}