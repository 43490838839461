
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.tg-th-all-check{
	&.--is-filled{
		--th-bg-color: #{ $primary };

		.el-checkbox__inner{
			border: 1px solid $white;
		}
	}
}