
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.nt-cell-action{
	&__type{
		display: flex;
		align-items: center;
		gap: 8px;

		.bs-btn{
			height: 24px;
			margin: 0;
			padding: 0 12px;
			border-radius: 2px;
		}
	}
	&__type-label{
		@include flex-ctr;
		height: 24px;
		padding: 0 12px;
		border-radius: 2px;

		color: $white;

		.type-agreement-success &{
			background-color: $marker-free;
		}
		.type-agreement-not &{
			background-color: $error;
		}
	}
	&__type-date{
		font-size: 10px;
		color: $light-text-secondary;
	}
}