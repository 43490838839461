
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
@import '@/styles/common/el-dialog-mixin';

.notify-popup{
	@include el-dialog;
	color: $light-text-regular;
	padding: 0;

	&__header {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;

		.svg-icon {
			width: 18px;
			height: 18px;
		}

		.check-green {
			color: $marker-free;
		}
		.check-red {
			color: $marker-busy;
		}

		p {
			font-size: 18px;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__select {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
}