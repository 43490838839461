
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.auth-layout {
	@include size(100%);
	position: relative;
	display: flex;
	flex-direction: column;

	&__container {
		@include size(100%);
	}
}