
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.employ-info-digital{
	position: relative;
	padding-bottom: 18px;

	&__one{}
	&__two{
		margin-top: 30px;
	}

	&__month-list{
		display: flex;
	}
	&__month-item{
		@include flex-ctr;
		user-select: none;
		cursor: pointer;
		height: 19px;
		width: calc( 100% / 12 - 2px);

		margin: 0 1px;
		padding-bottom: 3px;
		line-height: 1;
		color: $light-text-secondary;
		font-size: 12px;

		transition: all 200ms;
		&:hover{
			background-color: rgba($color: $primary, $alpha: 0.05);
		}

		.--is-dbl-mode &{
			width: calc( 100% / 6 - 2px);
		}

		&.type-added{
			border: 1px solid $marker-added;
			border-radius: 2px;
			color: $marker-added;
			background-color: $white;

			&:hover{
				background-color: rgba($color: $primary, $alpha: 0.05);
			}
		}

		&.type-select{
			border: 1px solid $marker-added;
			color: $white;
			background-color: $marker-added;
		}
	}
	&__surface-month-list{
		display: flex;
		align-items: stretch;
		margin-top: 5px;

		.--is-dbl-mode &{
			margin-top: 2px;
		}
	}
	&__surface-month-item{
		@include responsive-box(100%);
		// height: 44px;
		// width: calc( 100% / 12 - 2px);
		width: calc( 100% / 12 );

		// margin: 0 1px;

		.--is-dbl-mode &{
			@include responsive-box(calc(100% * 2 / 3 ));
			width: calc( 100% / 6);
		}

		.surface-month-item{
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__period{
		width: 100%;
		height: 12px;
		position: relative;

		font-size: 12px;
		line-height: 1;
		color: $light-text-secondary;
	}
	&__period-line{
		height: calc(100% - 1px);
		width: 40%;

		position: absolute;
		top: 1px;
		left: 40px;

		border: 1px solid $light-text-secondary;
		border-top: none;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;

		&.left-line-one-edge{
			border-bottom-right-radius: 0;
			border-right: none;
		}
		&.right-line-one-edge{
			border-bottom-left-radius: 0;
			border-left: none;
		}
	}
	&__period-from{
		position: absolute;
		top: calc(100% + 7px);
		left: 0;
		transform: translateX(-50%);
	}
	&__period-to{
		position: absolute;
		top: calc(100% + 7px);
		right: 0;
		transform: translateX(50%);
	}
}