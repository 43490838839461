
								@import '@/styles/utils/vars.scss';
								@import '@/styles/utils/mixins.scss';
							
.cell-mass-actions{
	$this: &;

	&__btn{
		cursor: pointer;
		position: relative;
		width: 26px;
		height: 53px;

		&:hover{
			#{$this}__btn-ico{
				transform: translate(-50%, -50%) scale(1.2);
			}
		}
	}
	&__btn-count{
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		font-size: 12px;
		color: $primary;
	}
	&__btn-ico{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		transition: 200ms;

		color: $light-text-regular;
		.ico{
			@include size(14px);
		}
	}

	&__popover{
		--el-popover-padding: 10px;

		&.el-popover{
			// min-width: 150px;
			min-width: auto;
		}
	}

	&__list{
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__item{
		text-align: right;
		font-size: 12px;
		color: $primary;

		.span{
			cursor: pointer;
			transition: 200ms;

			&:hover{
				opacity: 0.8;
				text-decoration: underline;
			}
		}
	}
}